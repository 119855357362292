import React from 'react';
import parse from 'html-react-parser';

// Component for displaying textual explanations of the Brinson model data
const TextExplanation = ({ title, explanation }) => {
  // Function to format the explanation text dynamically
  const formatText = (text) => {
    // Replace patterns for bold text, headings, and list items
    let formattedText = text
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Convert **bold** to <strong>
      .replace(/- (.*?)\n/g, '<li>$1</li>')             // Convert - items to list items
      .replace(/(\*\*.*?\*\*)/g, '</p><h2>$1</h2><p>');  // Create <h2> for headings
    
    // Add <ul> tags around the list items
    formattedText = formattedText.replace(/<li>/g, '<ul><li>').replace(/<\/li>/g, '</li></ul>');

    return formattedText;
  };

  return (
    <div className="text-explanation">
      <h2>{title}</h2>
      {/* Parse the formatted text */}
      <div>{parse(formatText(explanation))}</div>
    </div>
  );
};

export default TextExplanation;
