import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


const PerfBarChartComponent = ({ data }) => {
    return (
        <div style={{ textAlign: 'center' }}>
            <h3>Performance by Category</h3>
            <ResponsiveContainer width="100%" height={500}>
                <BarChart
                    layout="vertical"
                    data={data}
                    margin={{ top: 20, right: 30, left: 100, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" />
                    <YAxis dataKey="Category" type="category" />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="AggregatePortfolioReturn" fill="#8884d8" />
                    <Bar dataKey="AggregateBenchmarkReturn" fill="#82ca9d" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default PerfBarChartComponent;
