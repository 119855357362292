import React from 'react';

const SummaryTable = ({ data }) => (
  <table style={{ width: '100%', borderCollapse: 'collapse' }}>
    <thead>
      <tr>
        <th style={{ border: '1px solid black', padding: '8px', backgroundColor: '#f2f2f2' }}>Metric</th>
        <th style={{ border: '1px solid black', padding: '8px', backgroundColor: '#f2f2f2' }}>Value</th>
      </tr>
    </thead>
    <tbody>
      {data.map((item, index) => (
        <tr key={index}>
          <td style={{ border: '1px solid black', padding: '8px' }}>{item.MetricName}</td>
          <td style={{ border: '1px solid black', padding: '8px' }}>{item.BrinsonAttributionSummary}</td>
        </tr>
      ))}
    </tbody>
  </table>
);


export default SummaryTable;
